/* eslint-disable @typescript-eslint/no-unsafe-function-type */
export function memoizeFn<T extends Function>(fn: T) {
  const cache = new Map<string, any>()
  return function (...args: any[]) {
    const key = args.length > 0 ? JSON.stringify(args) : '__default__'
    if (cache.has(key)) {
      return cache.get(key)
    }
    const result = fn(...args)
    cache.set(key, result)
    return result
  }
}

export function memoizeTv<T extends Record<string, Function>>(obj: T): T {
  const newObj = { ...obj } as Record<string, Function>

  for (const key in newObj) {
    if (typeof newObj[key] === 'function') {
      newObj[key] = memoizeFn(newObj[key])
    }
  }

  return newObj as T
}
